import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import seo from 'tools/seo';

import LoginModal from 'components/Login/LoginModal';

export default function Register() {
  const history = useHistory();
  const { t } = useTranslation('register');

  return (
    <div className="Register">
      <Helmet>
        {seo.title(t('meta-title'))}
        {seo.description(t('meta-description'))}
      </Helmet>
      <LoginModal mode="register" onClose={() => history.push(`/`)} noDismiss={false} shouldRedirect />
    </div>
  );
}
